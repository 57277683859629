
<template>
<form autocomplete="off" class="hospital-patient-form">
  <is-loading v-if="isLoading" :box="true" />
  <div class="row gx-lg-4 g-3">
    <div class="col-12">
      <label> Select Doctor </label>
      <multiselect 
      mode="single"
      searchable
      valueProp="id"
      trackBy="name" label="name"
      :options="doctorOptions" 
      v-model="data.doctor_id" placeholder="--select doctor --">
      <template v-slot:option="{ option }">
        <div class="d-flex align-items-center">
          <img :src="absoluteUrl(option.image)" style="width:40px;height:40px;" class="me-2 rounded-circle" />
          <strong> {{ option.name }} - ({{ option.patients_on_queue_count }})</strong>
        </div>
      </template>
    </multiselect>
      <div v-for="(item, index) in v$.data.doctor_id.$errors"
        :key="index" class="text-danger">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>  

    <div v-if="!editMode" class="col-12">
      <label for="tag" class="form-label">Choose tag</label>
      <select v-model="data.tag" :class="{ 'is-invalid': v$.data.tag.$error }"
        name="tag" id="tag" class="form-select">
      <option disabled value="" >--choose tag--</option>
          <option v-for="(tag, sIndex) in tagOptions" :key="sIndex" 
          :value="tag.value">{{tag.label}}</option>
      </select>
      <div v-for="(item, index) in v$.data.tag.$errors"
        :key="index" class="text-danger">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div v-if="!editMode" class="col-12">
      <label class="form-label">Health Complaint</label>
      <textarea v-model="data.health_complaint" class="form-control"
      :class="{ 'is-invalid': v$.data.health_complaint.$error }"
      rows="5" placeholder="Write Health Complaint" name="health_complaint"></textarea>
      <div v-for="(item, index) in v$.data.health_complaint.$errors"
        :key="index" class="text-danger">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div> 
  </div>
  <div class="row mt-3 justify-content-end">
    <div class="col-12">
      <div class="d-flex mt-3 justify-content-end">
        <b-button variant="primary" 
        @click.prevent="editMode? update() : create()"
            type="button">{{editMode? 'Update':'Assign'}}</b-button>
      </div>
    </div>
  </div>
</form>
</template>

<script>
import { required, email, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import IsLoading from '@/components/IsLoading.vue'
import Multiselect from '@vueform/multiselect'

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    IsLoading,
    Multiselect
  },
  props:{
    patientId:{
      type: Number,
      default: null 
    },
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isLoading: true,
      data: {
        patient_id: this.patientId,
        doctor_id: null,
        tag: "",
        health_complaint: ""
      },
      mirrorData: null,
      tagOptions:[
        { value: "Normal", label: "Normal" },
        { value: "Urgent", label: "Urgent" }
      ],
    }
  },
  validations() {
    return {
      data:{
        doctor_id: {
          required: helpers.withMessage("Doctor is required", required),
        },
        tag: {
          required: helpers.withMessage("Tag is required", required),
        },
        health_complaint: {
          required: helpers.withMessage("Health complaint is required", required),
        },
      }
    }
  },
  computed:{
    doctorOptions(){
      return this.$store.state.doctors
    }
  },
  methods: {
    create() {
      this.v$.$validate().then(result =>{
        if (!result){ this.alertError('Form not valid'); return;}
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/hospital-patients/create', formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$emit('addItem', response.data.data)
            this.$store.commit("hospitalPatient/ADD_ITEM", response.data.data)
            this.resetForm();
          }
        })
      })
    },
    update(){
      this.v$.$validate().then(result =>{
        if (!result){ this.alertError('Form not valid'); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/hospital-patients/${this.editItem.id}/update`,formData)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit("hospitalPatient/UPDATE_ITEM", response.data.data)
              this.resetForm()
            }
          })
    
      })
    },
    getOptionData(){
      this.isLoading = true;
      this.$store.dispatch('fetchDoctors', true).then((response) => {
        this.isLoading = false;
      });
    },
    resetForm() {
      this.data = {...this.mirrorData}
      this.v$.$reset()
      this.$emit('closeMe')
    },
  },
  mounted(){
    this.getOptionData()
    this.mirrorData = JSON.parse(JSON.stringify(this.data))
    if(this.editMode){
      this.data = this.$filters.patchUpdatables(this.data, this.editItem)
    }
  }
}
</script>

<style>
.hospital-patient-form .multiselect-dropdown{
  max-height:30rem
}
</style>